import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    tenantId: "924bab93-d370-4ecb-9841-d71e015b4a7d", // Your tenant ID
    clientId: "4cecae43-77bf-4667-952a-298357638efc", // Your client (application) ID
    // authority:
    //   "https://login.microsoftonline.com/5a1f0e86-5162-4196-9d10-f16cf0e67595/v2.0/.well-known/openid-configuration", // Your tenant name and policy
    authority:
      "https://login.microsoftonline.com/924bab93-d370-4ecb-9841-d71e015b4a7d",
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};
