import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

export const NavContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const NavInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0.5rem 1.8rem;
  position: relative;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#FFFFFF")};

  @media (max-width: 769px) {
    padding: 1.2rem 1rem;
    justify-content: space-between;
  }
`;
export const NavDeleteInner = styled.div`
  width: 100%;
  display: none;
  align-items: center;
  padding: 0.9rem 1rem;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#FFFFFF")};
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;

  @media (max-width: 769px) {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    padding: 0.4rem 1rem;
  }
`;
export const SelectBox = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;
export const CrossButton = styled.div`
  cursor: pointer;
  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
  }
  @media (max-width: 600px) {
    img {
      height: 15px;
      width: 15px;
    }
  }
`;
export const SelectIconBox = styled.div`
  cursor: pointer;
  img {
    height: 30px;
    width: 30px;
    object-fit: contain;
  }
  @media (max-width: 600px) {
    img {
      height: 25px;
      width: 25px;
    }
  }
`;
export const NavLeftBox = styled.div`
  display: none;
  align-items: center;
  @media (max-width: 769px) {
    display: flex;
  }
`;
export const NavRightBox = styled.div`
  display: flex;
  align-items: center;
`;
export const BellIcon = styled.div`
  border-right: 1px solid #b3b9c6;
  padding-right: 0.7rem;
  cursor: pointer;
  img {
    height: 17px;
    width: 17px;
    object-fit: contain;
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
export const ThemeIcon = styled.div`
  padding-left: 0.7rem;
  cursor: pointer;
  img {
    height: 17px;
    width: 17px;
    object-fit: contain;
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
export const ThemeIconResp = styled.div`
  padding-left: 0.7rem;
  cursor: pointer;
  display: none;
  img {
    height: 38px;
    width: 38px;
    object-fit: contain;
  }
  @media (max-width: 769px) {
    display: flex;
  }
`;
export const ThemeIconRespTwo = styled.div`
  padding-left: 0.7rem;
  cursor: pointer;
  display: none;
  img {
    height: 17px;
    width: 17px;
    object-fit: contain;
  }
  @media (max-width: 769px) {
    display: flex;
  }
`;
export const Avatar = styled.div`
  padding-left: 0.9rem;
  border-radius: 50%;
  cursor: pointer;
  img {
    height: 32px;
    width: 32px;
    object-fit: contain;
    border-radius: 50%;
    border: ${(props) =>
      props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
export const AvatarSidebar = styled.div`
  /* padding-left: 0.9rem; */
  border-radius: 50%;
  cursor: pointer;
  display: none;
  img {
    height: 32px;
    width: 32px;
    object-fit: contain;
    border-radius: 50%;
  }
  @media (max-width: 769px) {
    display: flex;
  }
`;
export const DrawerBox = styled.div`
  display: none;
  padding-left: 0.9rem;
  /* border-radius: 50%; */
  @media (max-width: 769px) {
    display: flex;
  }
  img {
    height: 38px;
    width: 38px;
    object-fit: contain;
    /* border-radius: 50%; */
  }
`;
export const AccountBox = styled.div`
  width: 25%;
  display: flex;
  /* display: ${(props) => (props.isOpen ? "flex" : "none")}; */
  flex-direction: column;
  justify-content: left;
  align-items: center;
  position: absolute;
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#FFFFFF")};
  right: 0;
  top: 0;
  margin-top: 3rem;
  margin-right: 1rem;
  box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  border-radius: 8px;
  z-index: 9999;
`;
export const ProfileRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  padding: 1rem;
`;
export const ProfileBox = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 45px;
    width: 45px;
    object-fit: contain;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
    border-radius: 50%;
  }
`;
export const NameBox = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
`;
export const NameTitle = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
`;
export const NameEmail = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#667085")};
  margin-top: 2px;
`;
export const ComMenuBox = styled(NavLink)`
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#667085")};
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  padding: 0.8rem 1rem;
  cursor: pointer;

  :hover {
    background-color: ${(props) =>
    props.themeColor ? "#333741" : "#edeef1"};
  }

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-right: 0.5rem;
  }
`;

// Notification
export const NotificationBox = styled.div`
  width: 30%;
  display: flex;
  /* display: ${(props) => (props.isOpen ? "flex" : "none")}; */
  flex-direction: column;
  justify-content: left;
  align-items: center;
  position: absolute;
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#FFFFFF")};
  right: 0;
  top: 0;
  margin-top: 3rem;
  margin-right: 6rem;
  box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  border-radius: 8px;
  z-index: 9999;
`;
export const NotiTitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;
export const NotiTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
`;
export const SeeAllText = styled(NavLink)`
  font-size: 0.8rem;
  font-weight: 500;
  text-decoration: none;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#F05D24")};
  cursor: pointer;
`;
export const NotiContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const NotificationCard = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  padding: 0.8rem;
  gap: 0.8rem;
`;
export const NotiLogo = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  img {
    height: 35px;
    width: 35px;
    object-fit: contain;
  }
`;
export const NotiContent = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`;
export const NotiMessage = styled.div`
  width: 100%;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 18px;
  margin-bottom: 8px;
`;
export const TimeViewRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 0.8rem;
`;
export const TimeBox = styled.div`
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#8A94A6")};
  font-weight: 400;
  font-size: 0.8rem;
`;
export const ViewBox = styled.div`
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#F05D24")};
  font-weight: 600;
  font-size: 0.8rem;
`;
