import { lazy } from "react";
import ChangeUser from "../pages/private/User/ChangeUser";
const Login = lazy(() => import("../pages/public/adminLogin/Login"));
const Forgot = lazy(() => import("../pages/public/adminLogin/Forgot"));
const CheckMail = lazy(() => import("../pages/public/adminLogin/CheckMail"));
const ResetPass = lazy(() => import("../pages/public/adminLogin/ResetPass"));
const Success = lazy(() => import("../pages/public/adminLogin/Success"));
const GetOtp = lazy(() => import("../pages/public/adminLogin/GetOtp"));
const EnterOtp = lazy(() => import("../pages/public/adminLogin/EnterOtp"));
const OtpSuccess = lazy(() => import("../pages/public/adminLogin/OtpSuccess"));
const RedirectPage = lazy(() =>
  import("../pages/public/adminLogin/RedirectPage")
);

const LearnerLogin = lazy(() => import("../pages/public/learnerAdmin/Login"));
const LearnerForgot = lazy(() => import("../pages/public/learnerAdmin/Forgot"));
const LearnerCheckMail = lazy(() =>
  import("../pages/public/learnerAdmin/CheckMail")
);
const LearnerResetPass = lazy(() =>
  import("../pages/public/learnerAdmin/ResetPass")
);
const LearnerSuccess = lazy(() =>
  import("../pages/public/learnerAdmin/Success")
);
const TempSuccess = lazy(() => import("../pages/private/Template/Success"));
const Dashboard = lazy(() => import("../pages/private/Dashboard/Dashboard"));
const UserManagement = lazy(() =>
  import("../pages/private/User/UserManagement")
);
const GroupManagement = lazy(() =>
  import("../pages/private/Group/GroupManagement")
);
const AddGroup = lazy(() => import("../pages/private/Group/AddGroup"));
const NoPermission = lazy(() => import("../pages/private/Empty/NoPermission"));
const CategoryManagement = lazy(() =>
  import("../pages/private/Category/CategoryManagement")
);
const AddCategory = lazy(() => import("../pages/private/Category/AddCategory"));
const CourseManagement = lazy(() =>
  import("../pages/private/Course/CourseManagement")
);
const AddCourse = lazy(() => import("../pages/private/Course/AddCourse"));
const LanguageManagement = lazy(() =>
  import("../pages/private/Language/LanguageManagement")
);
const AddLanguage = lazy(() => import("../pages/private/Language/AddLanguage"));
const FeedbackManagement = lazy(() =>
  import("../pages/private/Feedback/FeedbackManagement")
);
const AddFeedback = lazy(() => import("../pages/private/Feedback/AddFeedback"));
const InteractiveManagement = lazy(() =>
  import("../pages/private/InteractiveQuizz/InteractiveQuizzManagment")
);
const AddInteractiveQuizz = lazy(() =>
  import("../pages/private/InteractiveQuizz/AddInteractiveQuizz")
);
const AccessQuizzManagement = lazy(() =>
  import("../pages/private/AssessQuizz/AssessQuizzManagement")
);
const AddAccessQuizz = lazy(() =>
  import("../pages/private/AssessQuizz/AddAssessQuizz")
);
const ContentManagement = lazy(() =>
  import("../pages/private/ContentManagement/ContentManagement")
);
const RoleManagement = lazy(() =>
  import("../pages/private/RoleManagement/RoleManagement")
);
const AddRole = lazy(() => import("../pages/private/RoleManagement/AddRole"));
const UserManagements = lazy(() =>
  import("../pages/private/UserManagement/UserManagement")
);
const AddUser = lazy(() => import("../pages/private/UserManagement/AddUser"));
const ClientManagement = lazy(() =>
  import("../pages/private/ClientManagement/ClientManagement")
);
const ClientGroups = lazy(() =>
  import("../pages/private/ClientManagement/ClientGroups")
);
const AddClient = lazy(() =>
  import("../pages/private/ClientManagement/AddClient")
);
const AddGroups = lazy(() =>
  import("../pages/private/ClientManagement/AddGroups")
);
const InactiveLearner = lazy(() =>
  import("../pages/private/BulkDelete/InactiveLearner")
);
const CourseDelete = lazy(() =>
  import("../pages/private/BulkDelete/CourseDelete")
);
const EpisodesDelete = lazy(() =>
  import("../pages/private/BulkDelete/EpisodesDelete")
);
const DesignationManagement = lazy(() =>
  import("../pages/private/Designation/DesignationManagement")
);
const AddDesignation = lazy(() =>
  import("../pages/private/Designation/AddDesignation")
);
const DepartmentManagement = lazy(() =>
  import("../pages/private/Depart/DepartManagement")
);

const AddDepartment = lazy(() =>
  import("../pages/private/Depart/AddDepartment")
);
const SurveyGroup = lazy(() => import("../pages/private/Survey/SurveyGroup"));
const SurveyForm = lazy(() => import("../pages/private/Survey/SurveyForm"));

const AddSurveyGroup = lazy(() =>
  import("../pages/private/Survey/AddSurveyGroup")
);
const AddSurveyForm = lazy(() =>
  import("../pages/private/Survey/AddSurveyForm")
);
const ProfileDetails = lazy(() =>
  import("../pages/private/Profile/ProfileDetails")
);
const Password = lazy(() => import("../pages/private/Profile/Password"));
const CompanyDetails = lazy(() =>
  import("../pages/private/Profile/CompanyDetails")
);
const Notification = lazy(() => import("../pages/private/Notification"));
const ReportManagement = lazy(() =>
  import("../pages/private/Reports/MasterReport.js")
);
const CourseReports = lazy(() =>
  import("../pages/private/Reports/SubReports/CourseReports.js")
);
const ClientReports = lazy(() =>
  import("../pages/private/Reports/SubReports/ClientReports.js")
);
const AgreementDateReports = lazy(() =>
  import("../pages/private/Reports/SubReports/AgreementDateReports.js")
);
const IndustryReports = lazy(() =>
  import("../pages/private/Reports/SubReports/IndustryReports.js")
);
const RollsReports = lazy(() =>
  import("../pages/private/Reports/SubReports/RollsReports.js")
);
const ProgressReports = lazy(() =>
  import("../pages/private/Reports/SubReports/ProgressReports.js")
);
const AuditReports = lazy(() =>
  import("../pages/private/Reports/SubReports/AuditReports.js")
);
const AddandLearnerDeleteReports = lazy(() =>
  import("../pages/private/Reports/SubReports/AddandLearnerDeleteReports.js")
);
const FeedbackReports = lazy(() =>
  import("../pages/private/Reports/SubReports/FeedbackReports.js")
);
const MasterReports = lazy(() =>
  import("../pages/private/Reports/SubReports/MasterReports.js")
);
const EntryReports = lazy(() =>
  import("../pages/private/Reports/SubReports/EntryReports.js")
);
const AddScheduleForm = lazy(() =>
  import("../pages/private/Reports/AddScheduleForm.js")
);
const AddInterest = lazy(() =>
  import("../pages/private/ManageContent/AddInterest")
);
const InterestMapping = lazy(() =>
  import("../pages/private/ManageContent/InterestMapping")
);

const EditBadges = lazy(() => import("../pages/private/Badges/EditBadges"));
const Badges = lazy(() => import("../pages/private/Badges/Badges"));

const AddMusicFile = lazy(() =>
  import("../pages/private/ManageContent/AddMusicFile")
);
const MusicFile = lazy(() =>
  import("../pages/private/ManageContent/MusicFile")
);
const LearnerCertificate = lazy(() =>
  import("../pages/private/Certificate/LearnerCertificate")
);

const Configuration = lazy(() =>
  import("../pages/private/Configuration/ConfigurationForm.js")
);

const AuditLogs = lazy(() => import("../pages/private/AuditLogs/AuditLogs"));

const Support = lazy(() => import("../pages/private/Support/Support"));
const Industry = lazy(() => import("../pages/private/Industry/Industry"));
const AddIndustry = lazy(() => import("../pages/private/Industry/AddIndustry"));
const AddBulkIndustry = lazy(() =>
  import("../pages/private/Industry/AddBulkIndustry")
);
const City = lazy(() => import("../pages/private/CityModule/City"));
const Specialcertificates = lazy(() =>
  import(
    "../pages/private/Certificate/SpecialCertificate/SpecialCertificate.js"
  )
);
const AddSpecialcertificateForm = lazy(() =>
  import(
    "../pages/private/Certificate/SpecialCertificate/AddSpecialCertificateForm.js"
  )
);
const AddUserType = lazy(() =>
  import("../pages/private/UserType/AddUserType.js")
);
const AddBulkUserType = lazy(() =>
  import("../pages/private/UserType/AddUserTypeBulk.js")
);
const UserTypeManagement = lazy(() =>
  import("../pages/private/UserType/UserTypeManagement.js")
);
const DashboardTwo = lazy(() =>
  import("../pages/private/Dashboard/DashboardTwo.js")
);
const AddFreeQuizz = lazy(() =>
  import("../pages/private/AssessQuizz/AddFreeQuizz.js")
);
const AddEditLearnerCertificate = lazy(() =>
  import("../pages/private/Certificate/AddEditLearnerCertificate.js")
);
const CategoryDelete = lazy(() =>
  import("../pages/private/BulkDelete/CategoryDelete.js")
);
const AddBadges = lazy(() => import("../pages/private/Badges/AddBadges.js"));
const AddBulkDepart = lazy(() =>
  import("../pages/private/Depart/AddBulkDepart.js")
);
const AddBulkDesig = lazy(() =>
  import("../pages/private/Designation/AddBulkDesig.js")
);
export const publicRoutes = [
  { path: "/", component: Login },
  { path: "/forgot", component: Forgot },
  { path: "/check-mail", component: CheckMail },
  { path: "/reset-password", component: ResetPass },
  { path: "/success", component: Success },
  { path: "/get-otp", component: GetOtp },
  { path: "/otp", component: EnterOtp },
  { path: "/otp-success", component: OtpSuccess },
  { path: "/redirect", component: RedirectPage },
  // { path: "/add-user", component: AddUser },
];
export const learnerRoutes = [
  { path: "/learner", component: LearnerLogin },
  { path: "/learner/forgot", component: LearnerForgot },
  { path: "/learner/check-mail", component: LearnerCheckMail },
  { path: "/learner/reset-password", component: LearnerResetPass },
  { path: "/learner/success", component: LearnerSuccess },
];
export const privateRoutes = [
  { path: "/welcome", component: TempSuccess },
  { path: "/dashboard", component: DashboardTwo },
  { path: "/user-management", component: UserManagement },
  { path: "/change-user", component: ChangeUser },
  // { path: "/group-management", component: GroupManagement },
  // { path: "/add-group", component: AddGroup },
  { path: "/no-permission", component: NoPermission },
  { path: "/category-management", component: CategoryManagement },
  { path: "/add-category", component: AddCategory },
  { path: "/course-management", component: CourseManagement },
  { path: "/add-course", component: AddCourse },
  { path: "/language-management", component: LanguageManagement },
  { path: "/add-language", component: AddLanguage },
  { path: "/feedback-from", component: FeedbackManagement },
  { path: "/add-feedback", component: AddFeedback },
  { path: "/interactive-quizz-management", component: InteractiveManagement },
  { path: "/add-interactive-quizz", component: AddInteractiveQuizz },
  { path: "/assessment-quizz-management", component: AccessQuizzManagement },
  { path: "/add-assessment-quizz", component: AddAccessQuizz },
  { path: "/add-free-quizz", component: AddFreeQuizz },
  { path: "/content-management", component: ContentManagement },
  { path: "/role", component: RoleManagement },
  { path: "/add-role", component: AddRole },
  { path: "/user", component: UserManagements },
  { path: "/add-user", component: AddUser },
  { path: "/client-management", component: ClientManagement },
  { path: "/add-client", component: AddClient },
  { path: "/client-groups", component: ClientGroups },
  { path: "/add-group", component: AddGroups },
  { path: "/inactive-learner", component: InactiveLearner },
  { path: "/category-delete", component: CategoryDelete },
  { path: "/course-delete", component: CourseDelete },
  { path: "/episode-delete", component: EpisodesDelete },
  { path: "/designation", component: DesignationManagement },
  { path: "/add-designation", component: AddDesignation },
  { path: "/department", component: DepartmentManagement },
  { path: "/add-department", component: AddDepartment },
  { path: "/user-type", component: UserTypeManagement },
  { path: "/add-user-type", component: AddUserType },
  { path: "/add-bulk-user-type", component: AddBulkUserType },
  { path: "/survey-groups", component: SurveyGroup },
  { path: "/add-survey-groups", component: AddSurveyGroup },
  { path: "/survey-forms", component: SurveyForm },
  { path: "/add-survey-form", component: AddSurveyForm },
  { path: "/profile-details", component: ProfileDetails },
  { path: "/password", component: Password },
  { path: "/company-details", component: CompanyDetails },
  { path: "/notification", component: Notification },
  { path: "/interest-mapping", component: InterestMapping },
  { path: "/add-interest", component: AddInterest },
  { path: "/badges", component: Badges },
  { path: "/edit-badges", component: EditBadges },
  { path: "/add-badges", component: AddBadges },
  { path: "/music-file", component: MusicFile },
  { path: "/add-music", component: AddMusicFile },
  // { path: "/master-reports", component: ReportManagement },
  { path: "/course-reports", component: CourseReports },
  { path: "/client-reports", component: ClientReports },
  { path: "/agreement-reports", component: AgreementDateReports },
  { path: "/industry-reports", component: IndustryReports },
  { path: "/roll-out-reports", component: RollsReports },
  { path: "/progress-reports", component: ProgressReports },
  { path: "/audit-reports", component: AuditReports },
  {
    path: "/add-delete-learner-reports",
    component: AddandLearnerDeleteReports,
  },
  { path: "/feedback-reports", component: FeedbackReports },
  { path: "/master-reports", component: MasterReports },
  { path: "/entry-reports", component: EntryReports },
  { path: "/add-schedule", component: AddScheduleForm },
  { path: "/learner-certificate", component: LearnerCertificate },
  { path: "/configuration", component: Configuration },
  { path: "/audit-logs", component: AuditLogs },
  { path: "/support", component: Support },
  { path: "/special-certificate", component: Specialcertificates },
  { path: "/add-special-certificate", component: AddSpecialcertificateForm },
  { path: "/industry-management", component: Industry },
  { path: "/add-industry", component: AddIndustry },
  { path: "/add-bulk-industry", component: AddBulkIndustry },
  { path: "/add-bulk-department", component: AddBulkDepart },
  { path: "/add-bulk-designation", component: AddBulkDesig },
  { path: "/city-management", component: City },
  { path: "/edit-learner-certificate", component: AddEditLearnerCertificate },
];
