import React, { useState, useEffect } from "react";
import * as UE from "./UserElement";
import { addUserValidator } from "../../../utils/validator";
import { Formik, Field, Form } from "formik";
import Input from "../../../components/InputAdd";
import axios from "../../../utils/axios";
import { useSelector, useDispatch } from "react-redux";
import rightArrow from "../../../assets/images/rightArrow.png";
import { Users, Groups, Permissions } from "../../../utils/api";

const ChangeUser = ({ navigate }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state);
  const [loginValues, setLoginValues] = useState({
    username: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    confirm_password: "",
    email: "",
    isStaff: false,
    isSuperAdmin: false,
    isUser: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const listArr = [
    {
      label: "Test 1",
      value: "Test 1",
    },
    {
      label: "Test 2",
      value: "Test 2",
    },
    {
      label: "Test 3",
      value: "Test 3",
    },
    {
      label: "Test 4",
      value: "Test 4",
    },
    {
      label: "Test 5",
      value: "Test 5",
    },
    {
      label: "Test 6",
      value: "Test 6",
    },
  ];

  // Groups
  const [listValues, setListValues] = useState([]);
  const [passValues, setPassValues] = useState([]);
  const [activeItems, setActiveItems] = useState({});
  const [groupListing, setGroupListing] = useState([]);

  // Permission
  const [listValuesTwo, setListValuesTwo] = useState([]);
  const [passValuesTwo, setPassValuesTwo] = useState([]);
  const [activeItemsTwo, setActiveItemsTwo] = useState({});
  const [listingArr, setListingArr] = useState([]);

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const toggleItem = (val) => {
    if (activeItems[val._id]) {
      // If the item is already active, remove it
      setActiveItems((prevActiveItems) => {
        const updatedItems = { ...prevActiveItems };
        delete updatedItems[val._id];
        return updatedItems;
      });
    } else {
      // If the item is not active, add it
      setActiveItems((prevActiveItems) => {
        const updatedItems = { ...prevActiveItems, [val._id]: true };
        return updatedItems;
      });
    }
  };

  const handleChooseAll = () => {
    const allItems = {};
    groupListing.forEach((item) => {
      allItems[item._id] = true;
    });
    setActiveItems(allItems);
    setListValues(groupListing);
  };
  const toggleItemTwo = (val) => {
    if (activeItemsTwo[val._id]) {
      // If the item is already active, remove it
      setActiveItemsTwo((prevActiveItems) => {
        const updatedItems = { ...prevActiveItems };
        delete updatedItems[val._id];
        return updatedItems;
      });
    } else {
      // If the item is not active, add it
      setActiveItemsTwo((prevActiveItems) => {
        const updatedItems = { ...prevActiveItems, [val._id]: true };
        return updatedItems;
      });
    }
  };

  const handleChooseAllTwo = () => {
    const allItems = {};
    listingArr.forEach((item) => {
      allItems[item._id] = true;
    });
    setActiveItemsTwo(allItems);
    setListValuesTwo(listingArr);
  };

  // const handlePermissionClick = (index) => {
  //   const updatedPermissions = [...selectedPermissions];
  //   updatedPermissions[index].check = !updatedPermissions[index].check;
  //   setSelectedPermissions(updatedPermissions);
  // };
  const handleLogin = async (values) => {
    console.log(values, "fromvales");
    let url = Users.user_register;
    setIsLoading(true);
    const formValues = {
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.username,
      isStaff: values.isStaff ? 1 : 0,
      isSuperAdmin: values.isSuperAdmin ? 1 : 0,
      isActive: values.isUser ? 1 : 0,
      isUser: values.isUser ? 1 : 0,
      email: values.email,
      password: values.password,
      phoneNumber: values.phoneNumber,
      countryCode: "+91",
      groupIds: passValues.map((val) => val._id),
      permissionId: passValuesTwo.map((val) => val._id),
    };
    console.log(formValues, "formValues");
    try {
      const { data } = await axios.post(url, formValues);
      console.log(data.data, "Login Data");
      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
      setIsLoading(false);
    }
  };

  const getGroups = async () => {
    let url = Groups.getGroups;
    try {
      const { data } = await axios.get(url);
      setGroupListing(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getPermissions = async () => {
    let url = Permissions.getPermissions;
    try {
      const { data } = await axios.get(url);
      setListingArr(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGroups();
    getPermissions();
  }, []);

  return (
    <UE.FormBox>
      <UE.InnerBox>
        <UE.HeadingRow>
          <UE.FirstHeading
            themeColor={theme?.isDark}
            onClick={() => navigate("/user-management")}
          >
            User Management
            <img src={rightArrow} alt="" />
          </UE.FirstHeading>
          <UE.SecondHeading themeColor={theme?.isDark}>
            Add new user
          </UE.SecondHeading>
        </UE.HeadingRow>
        <Formik
          enableReinitialize
          initialValues={loginValues}
          validate={addUserValidator}
          validateOnChange
          onSubmit={(values) => handleLogin(values, false)}
        >
          {(formikBag) => {
            return (
              <Form style={{ width: "100%", overflowY: "scroll" }}>
                <UE.FormTitle themeColor={theme?.isDark}>Add User</UE.FormTitle>
                <UE.FromDesc themeColor={theme?.isDark}>
                  First, enter a username and password. Then, you'll be able to
                  edit more user options.
                </UE.FromDesc>
                <UE.InputBoxContainer>
                  <UE.InputBox>
                    <Field name="username">
                      {({ field }) => (
                        <Input
                          {...field}
                          placeholder="User Name"
                          label="User Name"
                          value={formikBag.values.username}
                          onChange={(e) => {
                            formikBag.setFieldValue("username", e.target.value);
                          }}
                          error={
                            formikBag.touched.username &&
                            formikBag.errors.username
                              ? formikBag.errors.username
                              : null
                          }
                        />
                      )}
                    </Field>
                  </UE.InputBox>
                  <UE.InputBox>
                    <Field name="firstName">
                      {({ field }) => (
                        <Input
                          {...field}
                          placeholder="Enter First Name"
                          label="First Name"
                          value={formikBag.values.firstName}
                          onChange={(e) => {
                            formikBag.setFieldValue(
                              "firstName",
                              e.target.value
                            );
                          }}
                          error={
                            formikBag.touched.firstName &&
                            formikBag.errors.firstName
                              ? formikBag.errors.firstName
                              : null
                          }
                        />
                      )}
                    </Field>
                  </UE.InputBox>
                  <UE.InputBox>
                    <Field name="lastName">
                      {({ field }) => (
                        <Input
                          {...field}
                          placeholder="Enter Last Name"
                          label="Last Name"
                          value={formikBag.values.lastName}
                          onChange={(e) => {
                            formikBag.setFieldValue("lastName", e.target.value);
                          }}
                          error={
                            formikBag.touched.lastName &&
                            formikBag.errors.lastName
                              ? formikBag.errors.lastName
                              : null
                          }
                        />
                      )}
                    </Field>
                  </UE.InputBox>
                  <UE.InputBox>
                    <Field name="phoneNumber">
                      {({ field }) => (
                        <Input
                          {...field}
                          placeholder="Enter Mobile Number"
                          label="Mobile Number"
                          icon={true}
                          value={formikBag.values.phoneNumber}
                          onChange={(e) => {
                            formikBag.setFieldValue(
                              "phoneNumber",
                              e.target.value
                            );
                          }}
                          error={
                            formikBag.touched.phoneNumber &&
                            formikBag.errors.phoneNumber
                              ? formikBag.errors.phoneNumber
                              : null
                          }
                        />
                      )}
                    </Field>
                  </UE.InputBox>
                </UE.InputBoxContainer>
                <UE.BlueRowBox>Personal Info</UE.BlueRowBox>

                <UE.InputBoxContainer>
                  <UE.InputBox>
                    <Field name="password">
                      {({ field }) => (
                        <Input
                          {...field}
                          placeholder="Enter Password"
                          label="Password"
                          value={formikBag.values.password}
                          onChange={(e) => {
                            formikBag.setFieldValue("password", e.target.value);
                          }}
                          error={
                            formikBag.touched.password &&
                            formikBag.errors.password
                              ? formikBag.errors.password
                              : null
                          }
                        />
                      )}
                    </Field>
                  </UE.InputBox>
                  <UE.InputBox>
                    <Field name="confirm_password">
                      {({ field }) => (
                        <Input
                          {...field}
                          placeholder="Enter Confirm Password"
                          label="Confirm Password"
                          value={formikBag.values.confirm_password}
                          onChange={(e) => {
                            formikBag.setFieldValue(
                              "confirm_password",
                              e.target.value
                            );
                          }}
                          error={
                            formikBag.touched.confirm_password &&
                            formikBag.errors.confirm_password
                              ? formikBag.errors.confirm_password
                              : null
                          }
                        />
                      )}
                    </Field>
                  </UE.InputBox>
                  <UE.InputBox>
                    <Field name="email">
                      {({ field }) => (
                        <Input
                          {...field}
                          placeholder="Enter Email"
                          label="Email address"
                          value={formikBag.values.email}
                          onChange={(e) => {
                            formikBag.setFieldValue("email", e.target.value);
                          }}
                          error={
                            formikBag.touched.email && formikBag.errors.email
                              ? formikBag.errors.email
                              : null
                          }
                        />
                      )}
                    </Field>
                  </UE.InputBox>
                </UE.InputBoxContainer>
                <UE.BlueRowBox>Permissions</UE.BlueRowBox>
                {/* {activeList.map((val, ind) => {
                  return (
                  );
                })} */}
                <UE.CustomCheckBox
                  onClick={() =>
                    formikBag.setFieldValue(
                      "isStaff",
                      !formikBag.values.isStaff
                    )
                  }
                >
                  <UE.CheckRow>
                    <input type="checkbox" checked={formikBag.values.isStaff} />
                    <UE.CheckLable themeColor={theme?.isDark}>
                      Active
                    </UE.CheckLable>
                  </UE.CheckRow>
                  <UE.CheckDesc themeColor={theme?.isDark}>
                    It is a long established fact that a reader will be
                    distracted
                  </UE.CheckDesc>
                </UE.CustomCheckBox>
                <UE.CustomCheckBox
                  onClick={() =>
                    formikBag.setFieldValue("isUser", !formikBag.values.isUser)
                  }
                >
                  <UE.CheckRow>
                    <input type="checkbox" checked={formikBag.values.isUser} />
                    <UE.CheckLable themeColor={theme?.isDark}>
                      RM Admin
                    </UE.CheckLable>
                  </UE.CheckRow>
                  <UE.CheckDesc themeColor={theme?.isDark}>
                    It is a long established fact that a reader will be
                    distracted
                  </UE.CheckDesc>
                </UE.CustomCheckBox>
                <UE.CustomCheckBox
                  onClick={() =>
                    formikBag.setFieldValue(
                      "isSuperAdmin",
                      !formikBag.values.isSuperAdmin
                    )
                  }
                >
                  <UE.CheckRow>
                    <input
                      type="checkbox"
                      checked={formikBag.values.isSuperAdmin}
                    />
                    <UE.CheckLable themeColor={theme?.isDark}>
                      Super Admin
                    </UE.CheckLable>
                  </UE.CheckRow>
                  <UE.CheckDesc themeColor={theme?.isDark}>
                    It is a long established fact that a reader will be
                    distracted
                  </UE.CheckDesc>
                </UE.CustomCheckBox>

                {formikBag.values.isStaff ||
                formikBag.values.isSuperAdmin ||
                formikBag.values.isUser ? (
                  ""
                ) : (
                  <p style={{ color: "red", fontSize: "0.8rem", marginTop: 5 }}>
                    Please Check
                  </p>
                )}
                <UE.BlueRowBox>Groups</UE.BlueRowBox>
                <UE.PermissionBox>
                  <UE.ChooseFilter>
                    <UE.InnerChooseFilter>
                      <UE.ChooseHeader>
                        Available user groups{" "}
                        <i class="ph-fill ph-question"></i>
                      </UE.ChooseHeader>
                      <UE.FilterRow>
                        <i class="ph-bold ph-magnifying-glass"></i>
                        <UE.FilterBox type="text" placeholder="Filter" />
                      </UE.FilterRow>
                      <UE.FilterListBox>
                        {groupListing.map((val, ind) => {
                          const isActive = !!activeItems[val._id];

                          const handleClick = () => {
                            toggleItem(val);
                            setListValues((prevListValues) => {
                              if (isActive) {
                                return prevListValues.filter(
                                  (listVal) => listVal._id !== val._id
                                );
                              }
                              return [...prevListValues, val];
                            });
                          };
                          return (
                            <UE.FilterList
                              key={ind}
                              style={{
                                backgroundColor: isActive
                                  ? "lightslategray"
                                  : "transparent",
                                color: isActive
                                  ? "white"
                                  : theme?.isDark
                                  ? "white"
                                  : "black",
                              }}
                              onClick={handleClick}
                            >
                              {val.title}
                            </UE.FilterList>
                          );
                        })}
                      </UE.FilterListBox>
                    </UE.InnerChooseFilter>
                    <UE.AllSelectionRow
                      style={{
                        color: theme?.isDark ? "white" : "black",
                      }}
                      onClick={handleChooseAll}
                    >
                      Choose all <i class="ph-fill ph-caret-circle-right"></i>
                    </UE.AllSelectionRow>
                  </UE.ChooseFilter>
                  <UE.ArrowBox>
                    <UE.ComArrow onClick={() => setPassValues(listValues)}>
                      <i class="ph-fill ph-arrow-circle-right"></i>
                    </UE.ComArrow>
                    <UE.ComArrow>
                      <i class="ph-fill ph-arrow-circle-left"></i>
                    </UE.ComArrow>
                  </UE.ArrowBox>
                  <UE.ChooseFilter>
                    <UE.InnerChooseFilter>
                      <UE.ChooseHeader>
                        Choosen user permissions{" "}
                        <i class="ph-fill ph-question"></i>
                      </UE.ChooseHeader>
                      <UE.FilterRow>
                        <i class="ph-bold ph-magnifying-glass"></i>
                        <UE.FilterBox type="text" placeholder="Filter" />
                      </UE.FilterRow>
                      <UE.FilterListBox>
                        {passValues.length > 0 ? (
                          passValues.map((val, ind) => {
                            return (
                              <UE.FilterList
                                style={{
                                  color: theme?.isDark ? "white" : "black",
                                }}
                                key={ind}
                              >
                                {val.title}
                              </UE.FilterList>
                            );
                          })
                        ) : (
                          <UE.NoData
                            style={{
                              color: theme?.isDark ? "white" : "black",
                            }}
                          >
                            No Data
                          </UE.NoData>
                        )}
                      </UE.FilterListBox>
                    </UE.InnerChooseFilter>
                    <UE.AllSelectionRow
                      style={{
                        color: theme?.isDark ? "white" : "black",
                      }}
                      onClick={() => setPassValues([])}
                    >
                      <i class="ph-fill ph-caret-circle-left"></i> Remove all
                    </UE.AllSelectionRow>
                  </UE.ChooseFilter>
                </UE.PermissionBox>
                <UE.BlueRowBox>Permissions</UE.BlueRowBox>
                <UE.PermissionBox>
                  <UE.ChooseFilter>
                    <UE.InnerChooseFilter>
                      <UE.ChooseHeader>
                        Available user permissions{" "}
                        <i class="ph-fill ph-question"></i>
                      </UE.ChooseHeader>
                      <UE.FilterRow>
                        <i class="ph-bold ph-magnifying-glass"></i>
                        <UE.FilterBox type="text" placeholder="Filter" />
                      </UE.FilterRow>
                      <UE.FilterListBox>
                        {listingArr.map((val, ind) => {
                          const isActive = !!activeItemsTwo[val._id];

                          const handleClick = () => {
                            toggleItemTwo(val);
                            setListValuesTwo((prevListValues) => {
                              if (isActive) {
                                return prevListValues.filter(
                                  (listVal) => listVal._id !== val._id
                                );
                              }
                              return [...prevListValues, val];
                            });
                          };
                          return (
                            <UE.FilterList
                              key={ind}
                              style={{
                                backgroundColor: isActive
                                  ? "lightslategray"
                                  : "transparent",
                                color: isActive
                                  ? "white"
                                  : theme?.isDark
                                  ? "white"
                                  : "black",
                              }}
                              onClick={handleClick}
                            >
                              {val.title}
                            </UE.FilterList>
                          );
                        })}
                      </UE.FilterListBox>
                    </UE.InnerChooseFilter>
                    <UE.AllSelectionRow
                      style={{
                        color: theme?.isDark ? "white" : "black",
                      }}
                      onClick={handleChooseAllTwo}
                    >
                      Choose all <i class="ph-fill ph-caret-circle-right"></i>
                    </UE.AllSelectionRow>
                  </UE.ChooseFilter>
                  <UE.ArrowBox>
                    <UE.ComArrow
                      onClick={() => setPassValuesTwo(listValuesTwo)}
                    >
                      <i class="ph-fill ph-arrow-circle-right"></i>
                    </UE.ComArrow>
                    <UE.ComArrow>
                      <i class="ph-fill ph-arrow-circle-left"></i>
                    </UE.ComArrow>
                  </UE.ArrowBox>
                  <UE.ChooseFilter>
                    <UE.InnerChooseFilter>
                      <UE.ChooseHeader>
                        Choosen user permissions{" "}
                        <i class="ph-fill ph-question"></i>
                      </UE.ChooseHeader>
                      <UE.FilterRow>
                        <i class="ph-bold ph-magnifying-glass"></i>
                        <UE.FilterBox type="text" placeholder="Filter" />
                      </UE.FilterRow>
                      <UE.FilterListBox>
                        {passValuesTwo.length > 0 ? (
                          passValuesTwo.map((val, ind) => {
                            return (
                              <UE.FilterList
                                key={ind}
                                style={{
                                  color: theme?.isDark ? "white" : "black",
                                }}
                              >
                                {val.title}
                              </UE.FilterList>
                            );
                          })
                        ) : (
                          <UE.NoData
                            style={{
                              color: theme?.isDark ? "white" : "black",
                            }}
                          >
                            No Data
                          </UE.NoData>
                        )}
                      </UE.FilterListBox>
                    </UE.InnerChooseFilter>
                    <UE.AllSelectionRow
                      style={{
                        color: theme?.isDark ? "white" : "black",
                      }}
                      onClick={() => setPassValuesTwo([])}
                    >
                      <i class="ph-fill ph-caret-circle-left"></i> Remove all
                    </UE.AllSelectionRow>
                  </UE.ChooseFilter>
                </UE.PermissionBox>
                <UE.ButtonGroup>
                  <UE.CancelButton onClick={() => navigate("/user-management")}>
                    Cancel
                  </UE.CancelButton>
                  <UE.SaveButton
                    cursor={isLoading}
                    disabled={isLoading}
                    className="buttonload"
                    type="submit"
                  >
                    {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "Save"}
                  </UE.SaveButton>
                </UE.ButtonGroup>
              </Form>
            );
          }}
        </Formik>
      </UE.InnerBox>
    </UE.FormBox>
  );
};

export default ChangeUser;
