import React, { useEffect, useState, useRef } from "react";
import * as NS from "../../styles/NavStyled";
import * as SS from "../../styles/SidebarStyled";
import bellIcon from "../../assets/images/bell.png";
import bellDarkIcon from "../../assets/images/bellDark.png";
import lightIcon from "../../assets/images/light.png";
import darkIcon from "../../assets/images/dark.png";
import AvatarIcon from "../../assets/images/Avatar.png";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsDark,
  setTableLength,
  setSidebarIsOpens,
  setUserData,
  setErrorPop,
  setErrorPopMsg,
  setProfileData,
} from "../../store/action";
import darkLogo from "../../assets/images/rainLogoDark.png";
import mainLogo from "../../assets/images/rainLogo.png";
import drawer from "../../assets/images/drawer.png";
import darkDrawer from "../../assets/images/darkDrawer.png";
import crossBig from "../../assets/images/crossBig.png";
import selectIcon from "../../assets/images/selectIcon.png";
import accountIcon from "../../assets/images/accountIcon.png";
import logoutIconGrey from "../../assets/images/logoutIconGrey.png";
import infoIcon from "../../assets/images/infoIcon.png";
import warns from "../../assets/images/warns.png";
import darkMoon from "../../assets/images/darkMoon.png";
import { get } from "lodash";
import { GetNotification, ProfileInfo } from "../../utils/api";
import axios from "../../utils/axios";
import moment from "moment";

const Navbar = ({ navigate, dispatch }) => {
  const theme = useSelector((state) => state?.isDark);
  const deleteLength = useSelector((state) => state?.deleteLength);
  const profileUpdate = useSelector((state) => state?.profileUpdate);
  const profileData = useSelector((state) => state?.profileData);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [loginValues, setLoginValues] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [isRedBox, setIsRedBox] = useState(false);

  useEffect(() => {
    // Initialize theme from local storage if available
    const savedTheme = localStorage.getItem("rainTheme");
    if (savedTheme !== null) {
      dispatch(setIsDark(savedTheme === "true")); // Convert the string to a boolean
    }
  }, [dispatch]);

  const handleThemeChange = () => {
    const newTheme = !theme;
    localStorage.setItem("rainTheme", newTheme); // Save theme to local storage
    dispatch(setIsDark(newTheme));
  };
  const screenWidth = window.innerWidth;
  const profileRef = useRef(null); // Create a ref for the profile menu
  const notificationRef = useRef(null); // Create a ref for the profile menu

  // Close the profile menu if clicking outside of it
  useEffect(() => {
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileOpen(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsNotificationOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);
  const handleConfirm = () => {
    localStorage.removeItem("rainDataToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("rainRMUserData");
    dispatch(setUserData(""));
    navigate("/");
  };

  const getProfileDetails = async () => {
    try {
      let url = ProfileInfo.getProfileDetails;
      const { data } = await axios.get(url);
      if (data && data.data) {
        const { firstName, lastName, email, profileImage } = data.data;
        const userData = {
          firstName: firstName || "",
          lastName: lastName || "",
          email: email || "",
          profileImage: profileImage || "",
        };
        setLoginValues(userData);
        dispatch(setProfileData(userData));
        localStorage.setItem("rainRMUserData", JSON.stringify(userData));
      } else {
        setLoginValues({
          firstName: "",
          lastName: "",
          email: "",
          profileImage: "",
        });
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  useEffect(() => {
    if (
      !profileData ||
      Object.keys(profileData)?.length === 0 ||
      !profileUpdate
    ) {
      getProfileDetails();
    }
  }, [profileData]);

  const getNotification = async () => {
    let url = `${GetNotification.getNotification}?page=1&limit=6`;
    try {
      const { data } = await axios.get(url);
      if (data?.result) {
        setNotificationData(data?.result?.docs);
        const hasReadMark = data?.result?.docs?.some((val) => val.readMark);
        setIsRedBox(hasReadMark);
        setIsNotificationOpen(!isNotificationOpen);
      } else {
        setNotificationData([]);

        setIsNotificationOpen(!isNotificationOpen);
      }
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
    }
  };
  return (
    <NS.NavContainer>
      {screenWidth < 789 && deleteLength?.length > 0 ? (
        <NS.NavDeleteInner themeColor={theme}>
          <NS.CrossButton
            onClick={() => {
              dispatch(setTableLength([]));
            }}
          >
            <img src={crossBig} alt="" />
          </NS.CrossButton>
          <NS.SelectBox themeColor={theme}>
            {deleteLength?.length} Item Selected
          </NS.SelectBox>
          <NS.SelectIconBox>
            <img src={selectIcon} alt="" />
          </NS.SelectIconBox>
        </NS.NavDeleteInner>
      ) : (
        <NS.NavInner themeColor={theme}>
          <NS.NavLeftBox>
            <SS.MainLogo>
              <img src={theme ? darkLogo : mainLogo} alt="" />
            </SS.MainLogo>
          </NS.NavLeftBox>
          <NS.NavRightBox>
            <NS.BellIcon
              onClick={() => {
                getNotification();
              }}
            >
              <img src={theme ? bellDarkIcon : bellIcon} alt="" />
            </NS.BellIcon>
            <NS.ThemeIcon onClick={handleThemeChange}>
              <img src={theme ? darkIcon : lightIcon} alt="" />
            </NS.ThemeIcon>
            {theme ? (
              <NS.ThemeIconResp onClick={handleThemeChange}>
                <img src={darkMoon} alt="" />
              </NS.ThemeIconResp>
            ) : (
              <NS.ThemeIconRespTwo onClick={handleThemeChange}>
                <img src={lightIcon} alt="" />
              </NS.ThemeIconRespTwo>
            )}

            <NS.Avatar
              themeColor={theme}
              onClick={() => {
                setIsProfileOpen(!isProfileOpen);
              }}
            >
              <img src={profileData?.profileImage || AvatarIcon} alt="" />
            </NS.Avatar>
            {isProfileOpen ? (
              <NS.AccountBox themeColor={theme} ref={profileRef}>
                <NS.ProfileRow  themeColor={theme}>
                  <NS.ProfileBox themeColor={theme}>
                    <img src={profileData?.profileImage || AvatarIcon} alt="" />
                  </NS.ProfileBox>
                  <NS.NameBox themeColor={theme}>
                    <NS.NameTitle themeColor={theme}>
                      {profileData?.firstName} {profileData?.lastName}
                    </NS.NameTitle>
                    <NS.NameEmail themeColor={theme}>
                      {profileData?.email}
                    </NS.NameEmail>
                  </NS.NameBox>
                </NS.ProfileRow>
                <NS.ComMenuBox
                  themeColor={theme}
                  to="/profile-details"
                  onClick={() => {
                    setIsProfileOpen(false);
                  }}
                >
                  <img src={accountIcon} alt="" />
                  Account Settings
                </NS.ComMenuBox>
                <NS.ComMenuBox
                  themeColor={theme}
                  onClick={handleConfirm}
                  style={{ border: "none" }}
                >
                  <img src={logoutIconGrey} alt="" />
                  Log out
                </NS.ComMenuBox>
              </NS.AccountBox>
            ) : (
              ""
            )}
            {isNotificationOpen ? (
              <NS.NotificationBox themeColor={theme} ref={notificationRef}>
                <NS.NotiTitleRow>
                  <NS.NotiTitle themeColor={theme}>Notifications</NS.NotiTitle>
                  <NS.SeeAllText
                    to="/notification"
                    onClick={() => {
                      setIsNotificationOpen(false);
                    }}
                  >
                    See all
                  </NS.SeeAllText>
                </NS.NotiTitleRow>
                <NS.NotiContentBox>
                  {notificationData?.length > 0
                    ? notificationData?.map((val, ind) => {
                        // if (val.readMark) {
                        //   setIsRedBox(true);
                        // }
                        return (
                          <NS.NotificationCard key={ind} themeColor={theme}>
                            <NS.NotiLogo>
                              <img src={infoIcon} alt="" />
                            </NS.NotiLogo>
                            <NS.NotiContent>
                              <NS.NotiMessage themeColor={theme}>
                                {val.body}
                              </NS.NotiMessage>
                              <NS.TimeViewRow>
                                <NS.TimeBox themeColor={theme}>
                                  {moment(val.createdAt).format("h:mm:ss")}
                                  &nbsp;&nbsp;
                                  {moment(val.createdAt).format("DD MMM YYYY")}
                                </NS.TimeBox>
                                {val.viewStatus ? (
                                  <NS.ViewBox themeColor={theme}>
                                    View
                                  </NS.ViewBox>
                                ) : (
                                  ""
                                )}
                              </NS.TimeViewRow>
                            </NS.NotiContent>
                          </NS.NotificationCard>
                        );
                      })
                    : ""}
                  {/* <NS.NotificationCard>
                    <NS.NotiLogo>
                      <img src={infoIcon} alt="" />
                    </NS.NotiLogo>
                    <NS.NotiContent>
                      <NS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </NS.NotiMessage>
                      <NS.TimeViewRow>
                        <NS.TimeBox>18-01-2023 10:56:42</NS.TimeBox>
                        <NS.ViewBox>View</NS.ViewBox>
                      </NS.TimeViewRow>
                    </NS.NotiContent>
                  </NS.NotificationCard>
                  <NS.NotificationCard>
                    <NS.NotiLogo>
                      <img src={warns} alt="" />
                    </NS.NotiLogo>
                    <NS.NotiContent>
                      <NS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </NS.NotiMessage>
                      <NS.TimeViewRow>
                        <NS.TimeBox>18-01-2023 10:56:42</NS.TimeBox>
                      </NS.TimeViewRow>
                    </NS.NotiContent>
                  </NS.NotificationCard>
                  <NS.NotificationCard>
                    <NS.NotiLogo>
                      <img src={infoIcon} alt="" />
                    </NS.NotiLogo>
                    <NS.NotiContent>
                      <NS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </NS.NotiMessage>
                      <NS.TimeViewRow>
                        <NS.TimeBox>18-01-2023 10:56:42</NS.TimeBox>
                        <NS.ViewBox>View</NS.ViewBox>
                      </NS.TimeViewRow>
                    </NS.NotiContent>
                  </NS.NotificationCard>
                  <NS.NotificationCard>
                    <NS.NotiLogo>
                      <img src={infoIcon} alt="" />
                    </NS.NotiLogo>
                    <NS.NotiContent>
                      <NS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </NS.NotiMessage>
                      <NS.TimeViewRow>
                        <NS.TimeBox>18-01-2023 10:56:42</NS.TimeBox>
                        <NS.ViewBox>View</NS.ViewBox>
                      </NS.TimeViewRow>
                    </NS.NotiContent>
                  </NS.NotificationCard>
                  <NS.NotificationCard>
                    <NS.NotiLogo>
                      <img src={warns} alt="" />
                    </NS.NotiLogo>
                    <NS.NotiContent>
                      <NS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </NS.NotiMessage>
                      <NS.TimeViewRow>
                        <NS.TimeBox>18-01-2023 10:56:42</NS.TimeBox>
                      </NS.TimeViewRow>
                    </NS.NotiContent>
                  </NS.NotificationCard> */}
                </NS.NotiContentBox>
              </NS.NotificationBox>
            ) : (
              ""
            )}
            <NS.DrawerBox
              onClick={() => {
                dispatch(setSidebarIsOpens(true));
              }}
            >
              <img src={theme ? darkDrawer : drawer} alt="" />
            </NS.DrawerBox>
          </NS.NavRightBox>
        </NS.NavInner>
      )}
    </NS.NavContainer>
  );
};

export default Navbar;
