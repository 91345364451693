import React from "react";
import * as DE from "../styles/DetailEle";

const DetailLoader = () => {
  return (
    <>
        <DE.OverWrapper>
          <DE.Circle />
        </DE.OverWrapper>
    </>
  );
};

export default DetailLoader;
