export const USER_DATA = "USER_DATA";
export const IS_DARK = "IS_DARK";
export const IS_TOGGLE = "IS_TOGGLE";
export const ACCESS_MODULE = "ACCESS_MODULE";
export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";
export const DELETE_LENGTH = "DELETE_LENGTH";
export const SIDEBAR_TOGGLE_RESP = "SIDEBAR_TOGGLE_RESP";
export const IS_MODAL = "IS_MODAL";
export const PAGE_COUNT = "PAGE_COUNT";
export const MAIN_CLIENT_ID = "MAIN_CLIENT_ID";
export const MAIN_COURSE_ID = "MAIN_CLIENT_ID";
export const FIRST_MENU_ACTIVE = "FIRST_MENU_ACTIVE";
export const COURSE_ACTIVE = "COURSE_ACTIVE";
export const SECOND_MENU_ACTIVE = "SECOND_MENU_ACTIVE";
export const ASSESS_ID = "ASSESS_ID";
export const INTERACTID = "INTERACTID";
export const LANGUAGEID = "LANGUAGEID";
export const COURSEID = "COURSEID";
export const CATEGORYIDO = "CATEGORYIDO";
export const COURSEMENU = "COURSEMENU";
export const LANGUAGEIDOOO = "LANGUAGEIDOOO";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const COURSELANGUAGEID = "COURSELANGUAGEID";
export const COURSEMASTERID = "COURSEMASTERID";
export const COURSETYPE = "COURSETYPE";
export const EPISODEDATA = "EPISODEDATA";
export const FEEDBACKTYPE = "FEEDBACKTYPE";
export const ASSESSOBJ = "ASSESSOBJ";
export const INTEROBj = "INTEROBj";
export const ERROROBJ = "ERROROBJ";
export const ERRORPOPMSG = "ERRORPOPMSG";
export const PROFILEUPDATE = "PROFILEUPDATE";
export const PROFILEDATA = "PROFILEDATA";
