import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./components/Sidebar/Sidebar";
import Navbar from "./components/Navbar/Navbar";
import * as CS from "./styles/CommonStyled";
import * as SEC from "./styles/SectionStyled";

const LayoutPage = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  const toggle = useSelector((state) => state?.isToggle);
  const sidebarToggle = useSelector((state) => state?.isSidebarToggle);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/dashboard");
    }
  }, []);

  return (
    <CS.PrivateContainer themeColor={theme}>
      <CS.PrivateInnerContainer sidebarToggle={sidebarToggle}>
        <CS.PrivateFirstContainer
          isToggle={toggle}
          sidebarToggle={sidebarToggle}
        >
          <Sidebar
            navigate={navigate}
            location={location}
            dispatch={dispatch}
          />
        </CS.PrivateFirstContainer>
        <CS.PrivateSecondContainer themeColor={theme} isToggle={toggle}>
          <Navbar navigate={navigate} location={location} dispatch={dispatch} />
          <SEC.SectionContainer themeColor={theme}>
            <SEC.InnerContainer themeColor={theme}>
              <Outlet />
            </SEC.InnerContainer>
          </SEC.SectionContainer>
        </CS.PrivateSecondContainer>
      </CS.PrivateInnerContainer>
    </CS.PrivateContainer>
  );
};

export default LayoutPage;
